<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <div class="wrap" ref="wrap">
      <div class="ScaleBox" ref="ScaleBox">
        <webHead></webHead>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import webHead from '@/components/webHead.vue'
export default {
  name: "ScaleBox",
  data() {
    return {
      scale: null,
      width: 1536,
      height: 718,
      heightValue: 718,
      widthValue: 1536,
    };
  },
  components: {
    webHead
  },
  mounted() {
    // console.log(this);
    this.setScale();
    window.addEventListener("resize", this.setScale);
  },
  methods: {
    getScale() {
      const { width, height } = this;
      // 原始写法
      // console.log('浏览器',window.innerWidth,window.innerHeight);
      // let ww = window.innerWidth / width;
      // let wh = window.innerHeight / height;
      // console.log(ww,"设备比1920");
      // return ww < wh ? wh : ww;
      // 修改的写法
      // console.log(document.documentElement.clientWidth,"设备");
      // console.log(document.documentElement.clientHeight,"设备1");
      let ww = document.documentElement.clientWidth / width;
      let wh = document.documentElement.clientHeight / height;
      // console.log("设备2",ww);
      // console.log("设备3",wh);
      return ww < wh ? ww : wh;
      // return ww
    },
    setScale() {
      this.scale = this.getScale();
      this.$refs.ScaleBox.style.setProperty("--scale", this.scale); //设置指定键值对系统属性
      this.$refs.wrap.style.setProperty(
        "--heightValue",
        (this.scale * this.heightValue + 'px')
        // this.scale * this.heightValue
      );
      this.$refs.wrap.style.setProperty(
        "--widthValue",
         (this.scale * this.widthValue +'px')
      );
    },
  },
};
</script>
<style>

@import '@/utils/public.css';

</style>
<style lang="less" scoped>
#ScaleBox {
  --scale: 1;
}
.wrap {
  width: var(--widthValue);
  height: var(--heightValue);
  background-color: #011742;
  overflow: hidden;
}
 
.ScaleBox {
  width: 100%;
  height: 100%;
  transform: scale(var(--scale));
  transform-origin: 0 0;
}
</style>
<style>
 /*隐藏滚轮*/
::-webkit-scrollbar { 
  display: none;
  width: 0 !important;
}
html,body,div{
  -ms-overflow-style: none;
}
html,body,div{
  overflow: -moz-hidden-unscrollable;
}
</style>

