import request from '../utils/request.js' 
// 首页
export function homeIndex(data){   //首页数据
  return request ({
      method:'post',
      url:`index`,
      data
  })
}

export function homeGdp(data){   //首页GDP数据
  return request ({
      method:'post',
      url:`gdp`,
      data
  })
}

export function homeProjects(data){   //首页项目
  return request ({
      method:'post',
      url:`key/projects`,
      data
  })
}
// 要素保障
export function zhengFuServe(data){   //政府服务
  return request ({
      method:'post',
      url:`zhangfu`,
      data
  })
}
export function fenTyoe(data){   //获取分类
  return request ({
      method:'post',
      url:`touzi/chengben`,
      data
  })
}
export function typeInfo(data){   //获取详情
  return request ({
      method:'post',
      url:`touzi/info`,
      data
  })
}
// 我要投资
export function touZi(data){   //获取详情
  return request ({
      method:'post',
      url:`woyao`,
      data
  })
}
export function touZiType(data){   //获取详情
  return request ({
      method:'post',
      url:`woyao/type`,
      data
  })
}
// 投资优势
export function qianbian(data){   //汉中名片
  return request ({
      method:'post',
      url:`qianbian`,
      data
  })
}
export function hanZhongMing(data){   //汉中名片
  return request ({
      method:'post',
      url:`business`,
      data
  })
}
export function quYou(data){   //区位优势
  return request ({
      method:'post',
      url:`location`,
      data
  })
}
export function youFen(data){   //资源优势分类
  return request ({
      method:'post',
      url:`resources/type`,
      data
  })
}
export function huoZiYuan(data){   //获取资源
  return request ({
      method:'post',
      url:`resources/info`,
      data
  })
}
export function tourismCha(data){   //旅游资源查询
  return request ({
      method:'post',
      url:`tourism`,
      data
  })
}
export function zhongYaoZiyuan(data){   //中药资源
  return request ({
      method:'post',
      url:`medicine`,
      data
  })
}
export function biologicalZiyuan(data){   //生物资源
  return request ({
      method:'post',
      url:`biological`,
      data
  })
}
// 投资政策
export function zhengceType(data){   //产业政策分类
  return request ({
      method:'post',
      url:`zhengce/type`,
      data
  })
}
export function zhengceList(data){   //产业政策列表
  return request ({
      method:'post',
      url:`zhengceguanli`,
      data
  })
}
export function zhaoYin(data){   //人才招引
  return request ({
      method:'post',
      url:`rencai`,
      data
  })
}
export function zhaoYinTag(data){   //人才招引标签
  return request ({
      method:'post',
      url:`biaoqian`,
      data
  })
}
export function zhaoYinType(data){   //人才招引介绍类型
  return request ({
      method:'post',
      url:`zhao/type`,
      data
  })
}
export function shuishou(data){   //税收优惠政策
  return request ({
      method:'post',
      url:`shuishou`,
      data
  })
}
// 产业布局
export function chanYeType(data){   //产业布局类型
  return request ({
      method:'post',
      url:`chanye/type`,
      data
  })
}
export function chanYeInfo(data){   //查询子级分类
  return request ({
      method:'post',
      url:`chanye/info`,
      data
  })
}
export function chanYeLian(data){   //产业链招标方向
  return request ({
      method:'post',
      url:`get/chan/lian`,
      data
  })
}
export function zhongProject(data){   //重点项目
  return request ({
      method:'post',
      url:`get/zhong/project`,
      data
  })
}
export function zhongQiye(data){   //现有产业链重点企业
  return request ({
      method:'post',
      url:`get/zhong/qiye`,
      data
  })
}
export function gongzuo(data){   //工作目标
  return request ({
      method:'post',
      url:`gongzuo`,
      data
  })
}
// 资源要素
export function yaoSuType(data){   //资源要素分类
  return request ({
      method:'post',
      url:`ziyuan/type`,
      data
  })
}
export function yaoSuList(data){   //工作目标
  return request ({
      method:'post',
      url:`ziyuan/yaosu`,
      data
  })
}

// 搜索
export function searchText(data){   
  return request ({
      method:'post',
      url:`search/wide`,
      data
  })
}